import React, { useState, useEffect } from "react";
import { database, auth } from "../firebase";
import { ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";

const Wallet = ({ onSelectComponent }) => {
  const navigate = useNavigate();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [eBalance, setEBalance] = useState(0);
  const [pendingWithdraw, setPendingWithdraw] = useState(0);
  const [approvedWithdraw, setApprovedWithdraw] = useState(0);
  const [withdrawals, setWithdrawals] = useState([]);
  const [userId, setUserId] = useState(null);
  const [showWithdraw, setShowWithdraw] = useState(false); // State to manage withdraw visibility

  const showAlert = () => {
    alert("Withdraw on Rank 10 only!!");
  };
  const handleWithdrawClick = () => {
    setShowWithdraw(true); // Show Withdraw component
    onSelectComponent("withdraw"); // Update the active component in parent
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
        try {
          const userRef = ref(database, `users/${user.uid}`);
          const snapshot = await get(userRef);

          if (snapshot.exists()) {
            const userData = snapshot.val();
            setCurrentBalance(userData.coins || 0);
            setEBalance(userData.ecoins || 0);
            setPendingWithdraw(userData.pendingWithdraw || 0);
            setApprovedWithdraw(userData.approvedWithdraw || 0);
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchWithdrawals = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const withdrawRef = ref(database, `withdraws/${user.uid}/`);
          const snapshot = await get(withdrawRef);

          if (snapshot.exists()) {
            const withdrawalsData = snapshot.val();

            const withdrawalsList = Object.keys(withdrawalsData).map((key) => ({
              id: key, // Use the unique Firebase key as ID
              accountHolder: withdrawalsData[key].accountHolder,
              accountNumber: withdrawalsData[key].accountNumber,
              amount: withdrawalsData[key].amount,
              date: withdrawalsData[key].date,
              paymentMethod: withdrawalsData[key].paymentMethod,
              paymentStatus: withdrawalsData[key].paymentStatus,
            }));

            // Set the list of withdrawals in the state
            setWithdrawals(withdrawalsList);
            console.log(withdrawalsList);
          } else {
            console.log("No withdrawal data found");
          }
        } catch (error) {
          console.error("Error fetching withdrawal data:", error);
        }
      }
    };

    fetchUserData();
    fetchWithdrawals();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle missing date
    const dateObj = new Date(dateString);
    return !isNaN(dateObj.getTime())
      ? dateObj.toLocaleString()
      : "Invalid Date";
  };

  return (
    <div className="bg-gray-900 text-white p-6 sm:p-8 rounded-xl shadow-2xl m-6">
      {/* Wallet Overview */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <span className="text-lg font-semibold">Current Balance:</span>
        <span className="text-4xl font-extrabold text-yellow-400">
          {currentBalance} Rs
        </span>
      </div>

      <div className="flex flex-col sm:flex-row justify-between gap-4 mb-6">
        <button
          className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          onClick={handleWithdrawClick}
        >
          Withdraw Current Balance
        </button>
        <button
          className="w-full sm:w-auto bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          onClick={showAlert}
        >
          Withdraw in Best Wallet: {eBalance} Rs
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
          <span className="text-lg font-semibold">Pending Withdraw:</span>
          <p className="text-3xl font-extrabold text-yellow-400">
            {pendingWithdraw} Rs
          </p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
          <span className="text-lg font-semibold">Approved Withdraw:</span>
          <p className="text-3xl font-extrabold text-yellow-400">
            {approvedWithdraw} Rs
          </p>
        </div>
      </div>

      {/* Withdrawal History */}
      <h2 className="text-white text-3xl font-semibold mb-6">
        Withdrawal History
      </h2>
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-lg p-4">
        <table className="min-w-full table-auto text-left">
          <thead>
            <tr className="bg-gray-700 text-white">
              <th className="text-sm font-semibold py-3 px-6">
                Withdraw Amount
              </th>
              <th className="text-sm font-semibold py-3 px-6">
                Withdraw Status
              </th>
              <th className="text-sm font-semibold py-3 px-6">Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {withdrawals.length > 0 ? (
              withdrawals.map((withdrawal, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-600 hover:bg-gray-700"
                >
                  <td className="py-3 px-6">{withdrawal.amount} Rs</td>
                  <td className="py-3 px-6">{withdrawal.paymentStatus}</td>
                  <td className="py-3 px-6">{formatDate(withdrawal.date)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center py-4 text-gray-400">
                  No withdrawal history available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Wallet;
