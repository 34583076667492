import React, { useEffect, useState } from "react";
import { ref, onValue, update, get, set, remove } from "firebase/database";
import { database } from "../firebase"; // Firebase config
import "./Payments.css"; // Styles for buttons

const Payments = () => {
  const [userlist, setUserlist] = useState([]);
  const [userStatus, setUserStatus] = useState({});

  useEffect(() => {
    const paymentRef = ref(database, "payments");

    const unsubscribe = onValue(paymentRef, (snapshot) => {
      const paymentData = snapshot.val();
      const usersArray = paymentData
        ? Object.entries(paymentData).map(([key, value]) => ({
            id: key,
            ...value,
          }))
        : [];
      setUserlist(usersArray);
      fetchUserStatuses(usersArray);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserStatuses = (payments) => {
    const userIds = payments.map((payment) => payment.userId);

    userIds.forEach((userId) => {
      const userRef = ref(database, `users/${userId}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        setUserStatus((prevStatus) => ({
          ...prevStatus,
          [userId]: {
            payment: userData?.payment,
            referralCode: userData?.referralCode,
          },
        }));
      });
    });
  };

  const handleApproveClick = async (userId, paymentId, planPrice) => {
    if (!userId || !paymentId || !planPrice) {
      console.error("User ID, Payment ID, or Plan Price is missing.");
      return;
    }

    try {
      const userRef = ref(database, `users/${userId}`);
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (!userData) {
        console.error("User data not found.");
        return;
      }

      const { referralCode } = userData;

      await update(userRef, { payment: true });

      if (referralCode) {
        const usernamesRef = ref(database, "users");
        const usersSnapshot = await get(usernamesRef);

        let referredUserId = null;
        usersSnapshot.forEach((userSnapshot) => {
          const userData = userSnapshot.val();
          if (userData.username === referralCode) {
            referredUserId = userSnapshot.key;
          }
        });

        if (referredUserId) {
          const referredUserCoinsRef = ref(
            database,
            `users/${referredUserId}/coins`
          );
          const inviteUsersRef = ref(
            database,
            `users/${referredUserId}/invites`
          );

          const coinsSnapshot = await get(referredUserCoinsRef);
          const currentCoins = coinsSnapshot.val() || 0;
          const inviteSnapshot = await get(inviteUsersRef);
          const currentInvites = inviteSnapshot.val() || 0;

          const commission = planPrice * 0.23;

          await set(referredUserCoinsRef, currentCoins + commission);
          await set(inviteUsersRef, currentInvites + 1);
        } else {
          console.error("No user found with the matching referral code.");
        }
      }
    } catch (error) {
      console.error("Error processing payment approval: ", error);
    }
  };

  const handleRejectClick = async (userId, paymentId, planPrice) => {
    if (!userId || !paymentId) {
      console.error("User ID or Payment ID is missing.");
      return;
    }

    try {
      const userRef = ref(database, `users/${userId}`);
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (!userData) {
        console.error("User data not found.");
        return;
      }

      const { referralCode, walletBalance = 0 } = userData;

      // ادائیگی ڈیلیٹ کریں
      await remove(ref(database, `payments/${paymentId}`));

      // یوزر کی ادائیگی کی سٹیٹس کو false کریں
      await update(userRef, { payment: false });

      // مسترد شدہ رقم صارف کے اکاؤنٹ میں واپس ڈالیں
      await update(userRef, { walletBalance: walletBalance + planPrice });

      if (referralCode) {
        const usernamesRef = ref(database, "users");
        const usersSnapshot = await get(usernamesRef);

        let referredUserId = null;
        usersSnapshot.forEach((userSnapshot) => {
          const userData = userSnapshot.val();
          if (userData.username === referralCode) {
            referredUserId = userSnapshot.key;
          }
        });

        if (referredUserId) {
          const referredUserCoinsRef = ref(
            database,
            `users/${referredUserId}/coins`
          );
          const inviteUsersRef = ref(
            database,
            `users/${referredUserId}/invites`
          );

          const coinsSnapshot = await get(referredUserCoinsRef);
          const currentCoins = coinsSnapshot.val() || 0;
          const inviteSnapshot = await get(inviteUsersRef);
          const currentInvites = inviteSnapshot.val() || 0;

          const commission = planPrice * 0.23;

          // ریفرل سے کٹوتی کریں
          await set(
            referredUserCoinsRef,
            Math.max(currentCoins - commission, 0)
          );
          await set(inviteUsersRef, Math.max(currentInvites - 1, 0));
        }
      }

      console.log("Payment rejected and refunded successfully.");
    } catch (error) {
      console.error("Error rejecting payment: ", error);
    }
  };

  return (
    <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-6xl mx-auto">
      <h2 className="text-3xl font-semibold mb-6 text-center">Payments</h2>

      {/* Payments Table */}
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-md">
        <table className="w-full text-left">
          {/* Table Header */}
          <thead>
            <tr className="bg-gray-700 text-white text-sm uppercase">
              {[
                "ID",
                "Plan",
                "Payment Method",
                "Price",
                "Sender",
                "TRX ID",
                "Action",
                "Date",
              ].map((header) => (
                <th key={header} className="py-3 px-4 font-semibold">
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {userlist.length > 0 ? (
              userlist.map((user, index) => (
                <tr
                  key={user.id}
                  className="border-t border-gray-600 hover:bg-gray-700 transition duration-300"
                >
                  <td className="py-3 px-4">{index + 1}</td>
                  <td className="py-3 px-4">{user.planName}</td>
                  <td className="py-3 px-4">{user.paymentMethod}</td>
                  <td className="py-3 px-4">{user.planPrice} Rs</td>
                  <td className="py-3 px-4">{user.senderAccountName}</td>
                  <td className="py-3 px-4">{user.transactionId}</td>

                  <td className="py-3 px-4">
                    {userStatus[user.userId]?.payment ? (
                      <span className="px-4 py-2 bg-green-600 text-white rounded-md">
                        Approved
                      </span>
                    ) : (
                      <div className="flex gap-2">
                        <button
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                          onClick={() =>
                            handleApproveClick(
                              user.userId,
                              user.id,
                              user.planPrice
                            )
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                          onClick={() =>
                            handleRejectClick(
                              user.userId,
                              user.id,
                              user.planPrice
                            )
                          }
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </td>

                  <td className="py-3 px-4">{user.paymentDate}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="py-4 text-center text-gray-400">
                  No payments available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;
