import React, { useEffect, useState } from "react";
import { ref, onValue, update, remove } from "firebase/database"; // Added 'remove' to delete data
import { database } from "../firebase"; // Your Firebase setup file
import { FaEdit, FaTrash } from "react-icons/fa"; // Import edit and trash icons from React Icons
import "./Users.css";

const Users = () => {
  const [userData, setUserData] = useState(null);
  const [userlist, setUserlist] = useState([]);
  const [plans, setPlans] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingUserId, setEditingUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(""); // To manage the selected role in the dropdown

  useEffect(() => {
    // Fetch users from Firebase
    const userRef = ref(database, "users");

    const unsubscribe = onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      const usersArray = userData
        ? Object.entries(userData).map(([key, value]) => ({
            id: key,
            ...value,
          }))
        : [];
      setUserlist(usersArray);
    });

    return () => unsubscribe(); // Cleanup the subscription
  }, []);

  useEffect(() => {
    // Fetch plan data for each user
    userlist.forEach((user) => {
      if (user.selectedPlanId) {
        const planRef = ref(database, `plans/${user.selectedPlanId}`);

        const unsubscribe = onValue(planRef, (snapshot) => {
          const planData = snapshot.val();
          if (planData) {
            setPlans((prevPlans) => ({
              ...prevPlans,
              [user.id]: planData.name,
            }));
          }
        });

        return () => unsubscribe(); // Cleanup the subscription
      }
    });
  }, [userlist]);

  const handleEditClick = (user) => {
    setEditingUserId(user.id);
    setSelectedUser(user); // Set selected user for editing
    setSelectedRole(user.type || "user"); // Default to 'User' if no role is set
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleUpdate = () => {
    // Update the user's role in Firebase
    if (selectedUser) {
      const userRef = ref(database, `users/${selectedUser.id}`);
      update(userRef, { type: selectedRole })
        .then(() => {
          alert("User role updated successfully!");
          setEditingUserId(null); // Close editing view
          setSelectedUser(null); // Clear selected user
        })
        .catch((error) => {
          console.error("Error updating role:", error);
        });
    }
  };

  const handleDeleteClick = (userId) => {
    // Delete the user from Firebase
    const userRef = ref(database, `users/${userId}`);
    remove(userRef)
      .then(() => {
        alert("User deleted successfully!");
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const filteredUsers = userlist.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}/${month}/${year}`;
  };

  const getRank = (invites) => {
    if (invites <= 0) return "Rank 0";
    if (invites >= 1 && invites <= 10) return "Rank 1";
    if (invites >= 11 && invites <= 25) return "Rank 2";
    if (invites >= 26 && invites <= 50) return "Rank 3";
    if (invites >= 51 && invites <= 90) return "Rank 4";
    if (invites >= 91 && invites <= 140) return "Rank 5";
    if (invites >= 141 && invites <= 180) return "Rank 6";
    if (invites >= 181 && invites <= 220) return "Rank 7";
    if (invites >= 221 && invites <= 260) return "Rank 8";
    if (invites >= 261 && invites <= 300) return "Rank 9";
    return "Unranked";
  };

  return (
    <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-6xl mx-auto">
      <h2 className="text-3xl font-semibold mb-6 text-center">Users List</h2>

      {/* Search Input */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search by email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 bg-gray-800 text-white rounded-md border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
      </div>

      {/* Edit User Role Section */}
      {selectedUser && (
        <div className="bg-gray-800 p-5 rounded-lg mb-6">
          <h3 className="text-xl font-semibold mb-2">Edit User Role</h3>
          <p className="mb-4">
            <b>{selectedUser.name}</b> ({selectedUser.email})
          </p>
          <label className="block mb-4">
            <span className="text-gray-300">Role:</span>
            <select
              value={selectedRole}
              onChange={handleRoleChange}
              className="w-full p-3 mt-2 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="admin">Admin</option>
              <option value="user">User</option>
              <option value="block">Block</option>
            </select>
          </label>
          <button
            onClick={handleUpdate}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
          >
            Update Role
          </button>
        </div>
      )}

      {/* User List Table */}
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-md">
        <table className="w-full text-left">
          {/* Table Header */}
          <thead>
            <tr className="bg-gray-700 text-white text-sm uppercase">
              {[
                "ID",
                "Name",
                "Email",
                "Phone",
                "Username",
                "Rank",
                "Plan",
                "Payment",
                "Invites",
                "Register Date",
                "Edit",
                "Delete",
              ].map((header) => (
                <th key={header} className="py-3 px-4 font-semibold">
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => {
                const rank = getRank(user.invites || 0);
                return (
                  <tr
                    key={user.id}
                    className="border-t border-gray-600 hover:bg-gray-700 transition duration-300"
                  >
                    <td className="py-3 px-4">{index + 1}</td>
                    <td className="py-3 px-4">{user.name}</td>
                    <td className="py-3 px-4">{user.email}</td>
                    <td className="py-3 px-4">{user.phone}</td>
                    <td className="py-3 px-4">{user.username}</td>
                    <td className="py-3 px-4">{rank}</td>
                    <td className="py-3 px-4">
                      {plans[user.id] ? plans[user.id] : "No plan selected"}
                    </td>
                    <td
                      className="py-3 px-4 font-bold"
                      style={{ color: user.payment ? "green" : "red" }}
                    >
                      {user.payment ? "True" : "False"}
                    </td>
                    <td className="py-3 px-4">{user.invites}</td>
                    <td className="py-3 px-4">
                      {formatDate(user.creationDate)}
                    </td>
                    <td className="py-3 px-4 text-blue-500 cursor-pointer">
                      <FaEdit onClick={() => handleEditClick(user)} />
                    </td>
                    <td className="py-3 px-4 text-red-500 cursor-pointer">
                      <FaTrash onClick={() => handleDeleteClick(user.id)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="12" className="py-4 text-center text-gray-400">
                  No users available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
