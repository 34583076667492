import React, { useEffect, useState } from "react";
import { ref, onValue, update, get, remove } from "firebase/database";
import { database } from "../firebase"; // Firebase config

const Withdrawals = () => {
  const [userlist, setUserlist] = useState([]);

  useEffect(() => {
    const paymentRef = ref(database, "withdraws");

    // Listen to changes in the withdraws data in Firebase
    const unsubscribe = onValue(paymentRef, (snapshot) => {
      const paymentData = snapshot.val();

      const combinedWithdrawals = paymentData
        ? Object.entries(paymentData).flatMap(([userId, withdrawals]) =>
            Object.entries(withdrawals).map(
              ([withdrawalId, withdrawalData]) => ({
                userId,
                withdrawalId,
                ...withdrawalData,
              })
            )
          )
        : [];

      setUserlist(combinedWithdrawals);
    });

    return () => unsubscribe();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const dateObj = new Date(dateString);
    return !isNaN(dateObj.getTime())
      ? dateObj.toLocaleString()
      : "Invalid Date";
  };

  const handleApproveClick = async (userId, withdrawalId, amount) => {
    if (!userId || !withdrawalId || !amount) {
      console.error("User ID, Payment ID, or Amount is missing.");
      return;
    }

    try {
      const withdrawalRef = ref(
        database,
        `withdraws/${userId}/${withdrawalId}`
      );
      const userRef = ref(database, `users/${userId}`);

      const withdrawalSnapshot = await get(withdrawalRef);
      const withdrawalData = withdrawalSnapshot.val();

      if (!withdrawalData || withdrawalData.paymentStatus !== "pending") {
        console.error("No pending withdrawal found.");
        return;
      }

      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      const approvedWithdraw = userData?.approvedWithdraw || 0;
      const pendingWithdraw = userData?.pendingWithdraw || 0;

      await update(withdrawalRef, {
        paymentStatus: "approved",
        date: new Date().toISOString(),
      });

      await update(userRef, {
        approvedWithdraw: approvedWithdraw + amount,
        pendingWithdraw: Math.max(pendingWithdraw - amount, 0),
      });

      console.log("Withdrawal approved successfully.");
    } catch (error) {
      console.error("Error approving withdrawal: ", error);
    }
  };

  const handleRejectClick = async (userId, withdrawalId, amount) => {
    if (!userId || !withdrawalId || !amount) {
      console.error("User ID, Payment ID, or Amount is missing.");
      return;
    }

    try {
      const withdrawalRef = ref(
        database,
        `withdraws/${userId}/${withdrawalId}`
      );
      const userRef = ref(database, `users/${userId}`);

      const withdrawalSnapshot = await get(withdrawalRef);
      const withdrawalData = withdrawalSnapshot.val();

      if (!withdrawalData || withdrawalData.paymentStatus !== "pending") {
        console.error("No pending withdrawal found.");
        return;
      }

      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      const amountNumber = Number(amount);
      if (isNaN(amountNumber)) {
        console.error("Invalid amount");
        return;
      }

      const walletBalance = userData?.walletBalance || 0;
      const pendingWithdraw = userData?.pendingWithdraw || 0;

      // Firebase Transaction Update
      await Promise.all([
        remove(withdrawalRef),
        update(userRef, {
          coins: walletBalance + amountNumber,
          pendingWithdraw: Math.max(pendingWithdraw - amountNumber, 0),
        }),
      ]);

      console.log("Withdrawal rejected and amount refunded.");
    } catch (error) {
      console.error("Error rejecting withdrawal: ", error);
    }
  };

  return (
    <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-6xl mx-auto">
      <h2 className="text-3xl font-semibold mb-6 text-center">Payments</h2>

      {/* Payments Table */}
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-md">
        <table className="w-full text-left">
          {/* Table Header */}
          <thead>
            <tr className="bg-gray-700 text-white text-sm uppercase">
              {[
                "ID",
                "Username",
                "Payment Method",
                "Amount",
                "Account Holder",
                "AC No",
                "Action",
                "Date",
              ].map((header) => (
                <th key={header} className="py-3 px-4 font-semibold">
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {userlist.length > 0 ? (
              userlist.map((user, index) => (
                <tr
                  key={user.withdrawalId}
                  className="border-t border-gray-600 hover:bg-gray-700 transition duration-300"
                >
                  <td className="py-3 px-4">{index + 1}</td>
                  <td className="py-3 px-4">{user.accountHolder}</td>
                  <td className="py-3 px-4">{user.paymentMethod}</td>
                  <td className="py-3 px-4">${user.amount}</td>
                  <td className="py-3 px-4">{user.accountHolder}</td>
                  <td className="py-3 px-4">{user.accountNumber}</td>

                  <td className="py-3 px-4">
                    {user.paymentStatus === "pending" ? (
                      <div className="flex gap-2">
                        <button
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                          onClick={() =>
                            handleApproveClick(
                              user.userId,
                              user.withdrawalId,
                              user.amount
                            )
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                          onClick={() =>
                            handleRejectClick(
                              user.userId,
                              user.withdrawalId,
                              user.amount
                            )
                          }
                        >
                          Reject
                        </button>
                      </div>
                    ) : (
                      <span
                        className={`px-4 py-2 rounded-md text-white ${
                          user.paymentStatus === "approved"
                            ? "bg-green-600"
                            : "bg-yellow-500"
                        }`}
                      >
                        {user.paymentStatus}
                      </span>
                    )}
                  </td>

                  <td className="py-3 px-4">{user.date}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="py-4 text-center text-gray-400">
                  No payments available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdrawals;
