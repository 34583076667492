import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, set, update, push } from "firebase/database"; // Firebase database
import { getAuth } from "firebase/auth"; // Firebase authentication

const Withdraw = () => {
  const [accountHolder, setAccountHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("JazzCash");
  const [userBalance, setUserBalance] = useState(0);
  const [pendingWithdraw, setPendingWithdraw] = useState(0);
  const [isFirstWithdraw, setIsFirstWithdraw] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const db = getDatabase();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `users/${currentUser.uid}`);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserBalance(userData.coins || 0);
            setPendingWithdraw(userData.pendingWithdraw || 0);

            // Check if this is the first withdrawal
            const withdrawsRef = ref(db, `withdraws/${currentUser.uid}`);
            get(withdrawsRef).then((withdrawSnapshot) => {
              if (!withdrawSnapshot.exists()) {
                setIsFirstWithdraw(true); // If no withdraws exist, it's the first withdrawal
              } else {
                setIsFirstWithdraw(false);
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [currentUser, db]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!currentUser) {
      setError("User not authenticated.");
      return;
    }

    // Check withdrawal conditions
    if (isFirstWithdraw) {
      if (amount < 100) {
        setError("For the first withdrawal, the minimum amount is 100.");
        return;
      }
    } else {
      if (amount < 500) {
        setError(
          "Minimum withdrawal amount is 500 after the first withdrawal."
        );
        return;
      }
    }

    if (userBalance < amount) {
      setError("Insufficient balance for withdrawal.");
      return;
    }

    // Process the withdrawal
    const withdrawRef = ref(db, `withdraws/${currentUser.uid}`);
    const newWithdrawRef = push(withdrawRef);
    const withdrawData = {
      accountHolder,
      accountNumber,
      amount,
      paymentMethod,
      paymentStatus: "pending",
      username: currentUser.displayName,
      userId: currentUser.uid,
      date: new Date().toISOString(),
    };

    const updatedUserData = {
      coins: userBalance - amount,
      pendingWithdraw: pendingWithdraw + amount,
    };

    set(newWithdrawRef, withdrawData)
      .then(() => {
        const userRef = ref(db, `users/${currentUser.uid}`);
        return update(userRef, updatedUserData);
      })
      .then(() => {
        setSuccess("Withdrawal request submitted successfully.");
        setError("");
        setUserBalance((prev) => prev - amount);
        setPendingWithdraw((prev) => prev + amount);
      })
      .catch((error) => {
        setError("Error submitting the withdrawal request: " + error.message);
      });
  };

  return (
    <div className="bg-gray-900 text-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-auto drop-shadow-lg">
      <h2 className="text-3xl font-semibold text-center mb-6">Withdraw</h2>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      {success && <p className="text-green-500 text-center mb-4">{success}</p>}

      <form onSubmit={handleSubmit} className="space-y-2 w-full">
        <div>
          <label className="block text-lg font-medium mb-2">
            Account Holder Name
          </label>
          <input
            type="text"
            value={accountHolder}
            onChange={(e) => setAccountHolder(e.target.value)}
            required
            className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">
            Account Number
          </label>
          <input
            type="text"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            required
            className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            required
            min={isFirstWithdraw ? 100 : 500} // Conditional min value based on first withdrawal
            className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">
            Payment Method
          </label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          >
            <option value="JazzCash">JazzCash</option>
            <option value="EasyPaisa">EasyPaisa</option>
          </select>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="px-6 py-3 mt-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Withdraw;
