import React, { useState, useEffect } from "react";
import {
  ref,
  onValue,
  get,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { database, auth } from "../firebase";

const Team = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [data, setData] = useState(null);
  const [referralUsers, setReferralUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = auth.currentUser;
      if (user) {
        setCurrentUser(user.uid);
      } else {
        setLoading(false);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;

      try {
        const dbRef = ref(database, `users/${currentUser}`);
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          setData(userData);
        } else {
          setData(null);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    const fetchReferralUsers = async () => {
      if (!data || !data.username) return;

      try {
        const usersRef = ref(database, "users");
        const referralQuery = query(
          usersRef,
          orderByChild("referralCode"),
          equalTo(data.username)
        );

        const referralSnapshot = await get(referralQuery);
        if (referralSnapshot.exists()) {
          const referralData = referralSnapshot.val();
          const usersArray = Object.values(referralData);

          // Filter users with completed payments
          const filteredUsers = usersArray.filter(
            (user) => user.payment == true
          );
          setReferralUsers(filteredUsers);
        } else {
          setReferralUsers([]);
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchReferralUsers();
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="bg-gray-900 text-white p-6 sm:p-8 rounded-xl shadow-2xl w-full max-w-4xl mx-auto mt-6">
      <h1 className="text-3xl font-semibold text-center mb-6">Team</h1>

      {currentUser ? (
        <div>
          <p className="text-lg mb-4 text-center sm:text-left">
            Number of Referrals with Completed Payments:{" "}
            <b>{referralUsers.length}</b>
          </p>

          <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-md">
            <div className="grid grid-cols-1 sm:grid-cols-4 text-lg font-medium p-4 bg-gray-700 text-white">
              <p className="font-semibold">ID</p>
              <p className="font-semibold">Name</p>
              <p className="font-semibold">Username</p>
              <p className="font-semibold">Register Date</p>
            </div>

            {referralUsers.length > 0 ? (
              referralUsers.map((user, index) => (
                <div
                  key={user.id}
                  className="grid grid-cols-1 sm:grid-cols-4 p-4 border-t border-gray-600 hover:bg-gray-700 transition-all duration-300"
                >
                  <p className="text-center sm:text-left">{index + 1}</p>
                  <p className="text-center sm:text-left">{user.name}</p>
                  <p className="text-center sm:text-left">{user.username}</p>
                  <p className="text-center sm:text-left">
                    {formatDate(user.creationDate)}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-400 p-4">
                No referral users with completed payments found
              </p>
            )}
          </div>
        </div>
      ) : (
        <p className="text-center text-red-500 text-lg">No user logged in</p>
      )}
    </div>
  );
};

export default Team;
