import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase"; // Import useNavigate
// Import the CSS file
import { IoLogOut } from "react-icons/io5";
import Logo from "./Logo";
const Dashboard = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const items = [
    { id: 1, title: "Users" },
    { id: 2, title: "Today Work" },
    { id: 3, title: "Daily Reward" },
    { id: 4, title: "Plans" },
    { id: 5, title: "Website Data" },
    { id: 6, title: "Payments" },
    { id: 7, title: "Withdraw" },
    { id: 8, title: "Offer Headline" },
    { id: 9, title: "Add Payment" },
  ];

  const handleItemClick = (title) => {
    switch (title) {
      case "Users":
        navigate("/users"); // Navigate to Profile page
        break;
      case "Today Work":
        navigate("/task"); // Add navigation for other items as needed
        break;
      case "Daily Reward":
        navigate("/dailybonuss");
        break;
      case "Plans":
        navigate("/plans");
        break;
      case "Website Data":
        navigate("/websitedetail");
        break;

      case "Payments":
        navigate("/paymentslist");
        break;
      case "Withdraw":
        navigate("/withdrawal");
        break;
      case "Offer Headline":
        navigate("/offers");
        break;
      case "Add Payment":
        navigate("/addpayment");
        break;

      default:
        break;
    }
  };
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      {/* Top Section */}
      <div className="flex justify-between items-center mb-6">
        <Logo />
        <IoLogOut
          className="text-2xl cursor-pointer hover:text-red-500 transition-colors"
          onClick={handleLogout}
        />
      </div>

      <p className="text-2xl font-semibold text-center mb-6">Admin Dashboard</p>

      {/* Dashboard Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {items.map((item) => (
          <div
            key={item.id}
            className="bg-gray-800 p-6 rounded-lg shadow-lg text-center cursor-pointer transition-all duration-300 hover:bg-gray-700 hover:scale-105"
            onClick={() => handleItemClick(item.title)}
          >
            <p className="text-lg font-medium">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
