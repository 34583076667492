import React from "react";
import { FaPlusSquare, FaUserCircle } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { MdGroups3 } from "react-icons/md";
import { FcInvite } from "react-icons/fc";
import { AiFillFire } from "react-icons/ai";

const DashboardCards = ({ onSelectComponent }) => {
  return (
    <div className="mt-12 mb-6 px-2">
      <div className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {[
          {
            title: "Profile",
            description: "View Your Profile and see your Rank",
            icon: <FaUserCircle className="w-10 h-10 text-blue-600" />,
            component: "profile",
          },
          {
            title: "Today Work",
            description: "View Your today work and complete your task",
            icon: <FaPlusSquare className="w-10 h-10 text-green-500" />,
            component: "todaywork",
          },
          {
            title: "Wallet",
            description: "View Your current Balance",
            icon: <IoWallet className="w-10 h-10 text-yellow-500" />,
            component: "wallet",
          },
          {
            title: "My Team",
            description: "View Your Team and Progress",
            icon: <MdGroups3 className="w-10 h-10 text-purple-500" />,
            component: "team",
          },
          {
            title: "Invite Team",
            description:
              "Share your referral link with your friends and get money",
            icon: <FcInvite className="w-10 h-10" />,
            component: "invite",
          },
          {
            title: "Daily Rewards",
            description: "Earn money by claiming your rewards",
            icon: <AiFillFire className="w-10 h-10 text-red-500" />,
            component: "reward",
          },
        ].map(({ title, description, icon, component }, index) => (
          <div
            key={index}
            className="bg-[#0b1739] shadow-lg p-4 w-full rounded-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:bg-[#13214b] flex flex-col h-full"
          >
            <div className="flex justify-center">
              <div className="flex w-min bg-[#edf2f7] rounded-full p-2 shadow-md">
                {icon}
              </div>
            </div>

            <div className="mt-3 text-center flex-grow">
              <h3 className="text-lg font-bold text-white">{title}</h3>
              <p className="mt-2 text-xs text-gray-300">{description}</p>
            </div>

            <div className="mt-auto flex justify-center">
              <button
                onClick={() => onSelectComponent(component)}
                className="px-3 py-1.5 bg-blue-600 rounded-md text-white shadow-md text-sm transition-all duration-300 hover:bg-blue-700 hover:scale-105"
              >
                Click here
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardCards;
