import React, { useEffect, useState } from "react";
import { ref, onValue, update } from "firebase/database";
import { database } from "../firebase"; // Your Firebase setup file
import { FaEdit } from "react-icons/fa"; // Import the edit icon from React Icons

const AddPayments = () => {
  const [userlist, setUserlist] = useState([]);
  const [plans, setPlans] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [editingUserId, setEditingUserId] = useState(null);
  const [currentBalance, setCurrentBalance] = useState("");
  const [pendingBalance, setPendingBalance] = useState("");

  useEffect(() => {
    // Fetch users from Firebase
    const userRef = ref(database, "users");

    const unsubscribe = onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      const usersArray = userData
        ? Object.entries(userData).map(([key, value]) => ({
            id: key,
            ...value,
          }))
        : [];
      setUserlist(usersArray);
    });

    return () => unsubscribe(); // Cleanup the subscription
  }, []);

  useEffect(() => {
    // Fetch plan data for each user
    userlist.forEach((user) => {
      if (user.selectedPlanId) {
        const planRef = ref(database, `plans/${user.selectedPlanId}`);

        const unsubscribe = onValue(planRef, (snapshot) => {
          const planData = snapshot.val();
          if (planData) {
            setPlans((prevPlans) => ({
              ...prevPlans,
              [user.id]: planData.name,
            }));
          }
        });

        return () => unsubscribe(); // Cleanup the subscription
      }
    });
  }, [userlist]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleEditClick = (user) => {
    setEditingUserId(user.id);
    setCurrentBalance(user.coins || "");
    setPendingBalance(user.pendingWithdraw || "");
  };

  const handleSave = async () => {
    if (editingUserId) {
      const userRef = ref(database, `users/${editingUserId}`);
      await update(userRef, {
        coins: Number(currentBalance),
        pendingWithdraw: Number(pendingBalance),
      });
      setEditingUserId(null);
      setCurrentBalance("");
      setPendingBalance("");
    }
  };

  const filteredUserlist = userlist.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm) ||
      user.name.toLowerCase().includes(searchTerm)
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };
  const getRank = (invites) => {
    if (invites <= 0) return "Rank 0";
    if (invites >= 1 && invites <= 10) return "Rank 1";
    if (invites >= 11 && invites <= 25) return "Rank 2";
    if (invites >= 26 && invites <= 50) return "Rank 3";
    if (invites >= 51 && invites <= 90) return "Rank 4";
    if (invites >= 91 && invites <= 140) return "Rank 5";
    if (invites >= 141 && invites <= 180) return "Rank 6";
    if (invites >= 181 && invites <= 220) return "Rank 7";
    if (invites >= 221 && invites <= 260) return "Rank 8";
    if (invites >= 261 && invites <= 300) return "Rank 9";
    return "Unranked";
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
        Users List
      </h2>

      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
      />

      {/* Edit Form */}
      {editingUserId && (
        <div className="bg-white p-6 shadow-md rounded-md mb-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">
            Edit User
          </h3>
          <div className="space-y-2">
            <label className="block">
              <span className="text-gray-600">Current Balance:</span>
              <input
                type="number"
                value={currentBalance}
                onChange={(e) => setCurrentBalance(e.target.value)}
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
              />
            </label>
            <label className="block">
              <span className="text-gray-600">Pending Balance:</span>
              <input
                type="number"
                value={pendingBalance}
                onChange={(e) => setPendingBalance(e.target.value)}
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
              />
            </label>
          </div>
          <div className="mt-4 flex gap-2">
            <button
              onClick={handleSave}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
            >
              Save
            </button>
            <button
              onClick={() => setEditingUserId(null)}
              className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Users Table */}
      <div className="overflow-x-auto bg-white shadow-md rounded-md">
        <table className="w-full border-collapse">
          <thead className="bg-blue-500 text-white">
            <tr>
              {[
                "ID",
                "Name",
                "Email",
                "Plan",
                "Rank",
                "Current Balance",
                "Pending Balance",
                "Invites",
                "Edit",
              ].map((header, index) => (
                <th
                  key={index}
                  className="px-4 py-2 text-left border border-blue-400"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredUserlist.length > 0 ? (
              filteredUserlist.map((user, index) => {
                const rank = getRank(user.invites || 0);
                return (
                  <tr key={user.id} className="hover:bg-gray-100 transition">
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">{user.username}</td>
                    <td className="border px-4 py-2">{user.email}</td>
                    <td className="border px-4 py-2">
                      {plans[user.id] || "No plan selected"}
                    </td>
                    <td className="border px-4 py-2">{rank}</td>
                    <td className="border px-4 py-2">{user.coins || 0}</td>
                    <td className="border px-4 py-2">
                      {user.pendingWithdraw || 0}
                    </td>
                    <td className="border px-4 py-2">{user.invites}</td>
                    <td className="border px-4 py-2 text-center">
                      <FaEdit
                        className="cursor-pointer text-blue-500 hover:text-blue-700 transition"
                        onClick={() => handleEditClick(user)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="9" className="text-center text-gray-500 py-4">
                  No users available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddPayments;
