import React from "react";
import { auth } from "../firebase"; // Firebase Auth
import useUserData from "../hooks/useUserData"; // Import the reusable hook
import { IoMdCamera } from "react-icons/io";

const Profile = () => {
  const { data: userData, loading, error } = useUserData(); // Use the hook

  const getRank = (invites) => {
    if (invites <= 0) return "Rank 0";
    if (invites >= 1 && invites <= 10) return "Rank 1";
    if (invites >= 11 && invites <= 25) return "Rank 2";
    if (invites >= 26 && invites <= 50) return "Rank 3";
    if (invites >= 51 && invites <= 90) return "Rank 4";
    if (invites >= 91 && invites <= 140) return "Rank 5";
    if (invites >= 141 && invites <= 180) return "Rank 6";
    if (invites >= 181 && invites <= 220) return "Rank 7";
    if (invites >= 221 && invites <= 260) return "Rank 8";
    if (invites >= 261 && invites <= 300) return "Rank 9";
    return "Unranked";
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData) return <p>No user data available</p>;

  const rank = getRank(userData.invites || 0);

  return (
    <div className="min-h-screen mt-4 text-white p-2 bg-gray-900">
      {/* Dashboard Container */}
      <div className="container mx-auto max-w-4xl p-6 bg-gray-800 rounded-lg shadow-2xl">
        {/* Profile Header Section */}
        <div className="flex flex-col sm:flex-row items-center sm:justify-between mb-8">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <img
                src="/images.jpg"
                alt="Profile"
                className="w-20 h-20  object-cover rounded-full border-4 border-white shadow-lg"
              />
              <IoMdCamera className="absolute bottom-2 right-2 text-white text-2xl cursor-pointer transform hover:scale-110 transition-transform" />
            </div>
            <div>
              <h2 className="text-3xl font-semibold">
                {userData.name || "N/A"}
              </h2>
              <p className="text-sm text-gray-400">
                {auth.currentUser?.email || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Profile Stats Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
          {[
            { title: "Rank", value: rank, color: "from-blue-600 to-blue-400" },
            {
              title: "Username",
              value: userData.username || "N/A",
              color: "from-green-600 to-green-400",
            },
            {
              title: "Phone Number",
              value: userData.phone || "N/A",
              color: "from-purple-600 to-purple-400",
            },
          ].map(({ title, value, color }, index) => (
            <div
              key={index}
              className={`bg-gradient-to-r ${color} p-4 rounded-xl shadow-lg transform hover:scale-105 transition duration-300`}
            >
              <h3 className="text-lg font-semibold">{title}</h3>
              <p className="text-2xl mt-2 font-bold">{value}</p>
            </div>
          ))}
        </div>

        {/* Profile Information Section */}
        <div className="mt-8 space-y-4">
          {[
            { label: "Email", value: auth.currentUser?.email || "N/A" },
            { label: "Total Invites", value: userData.invites || 0 },
          ].map(({ label, value }, index) => (
            <div
              key={index}
              className="flex justify-between py-4 border-b border-gray-600"
            >
              <span className="text-lg text-gray-400">{label}</span>
              <span className="text-lg font-medium">{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
